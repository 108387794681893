<template>
  <tr>
    <td>
      <div class="form-check">
        <input v-model="item.for_total_cost" class="form-check-input" type="checkbox"
               tabindex="3"/>
      </div>
    </td>
    <td colspan="5" style="min-width: 250px">
      <vField
          v-model="item.product_costingable_id"
          name="product_costingable_id"
          type="text"
          class="form-control d-none"
      />
      <AsyncSelect
          :api-service="fetchAccountHeadsList"
          placeholder="Select Account Head"
          v-model="selectedProduct"
          :format-label="option => option.name"
          :additional-option="additionalOptions"
          label="name"
      />
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.quantity"
            type="number"
            class="form-control text-center"
            placeholder="Qty"
            :min="0"
        >
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            v-model="item.rate"
            placeholder="Rate"
            type="text"
            class="form-control text-center"
        />
      </div>
    </td>
    <td>
      <div class="td-inline">
        <input
            :value="finalAmount"
            placeholder="Amount"
            type="text"
            class="form-control text-center"
            readonly
        />
      </div>
    </td>
    <td>
      <div>
        <button @click="(e) => deleteItem(e, index)" class="btn btn-sm btn-dlt btn-danger">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </td>
  </tr>
</template>

<script setup>
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {computed, ref, watch} from 'vue';
import handleJournal from '@/services/modules/journal'
import {useRoute} from "vue-router";
const props = defineProps(['item', 'index', 'onDeleteItem'])
const emit = defineEmits(['onDeleteItem']);
const route = useRoute();
const {fetchAccountHeadsList} = handleJournal()
const selectedProduct = ref(props.item.product);

const additionalOptions = computed(() => {
  if (route.params.productCostingId && props.item.product_costingable_id && props.item.product) {
    return [
      {
        id: props.item.product_costingable_id,
        name: props.item.product.name,
      }
    ]
  }
});

watch(selectedProduct, (newValue) => {
  if (newValue) {
    props.item.product = {
      name: newValue.name,
      ...newValue
    }
    props.item.product_costingable_id = newValue.id;
  }
});

const finalAmount = computed(() => {
  const quantity = parseFloat(props.item.quantity);
  const amount = quantity * props.item.rate;
  props.item.amount = amount.toFixed(4);
  return props.item.amount;
});

const deleteItem = (e, i) => {
  e.stopPropagation();
  emit('onDeleteItem', i)
}
</script>

<style>
.create-billing > :not(first-child) > * {
  padding-left: 10px !important;
}

.create-billing > :not(caption) > * > * {
  padding: 0.72rem 0.2rem;
}
</style>
